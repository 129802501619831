import {
  Badge,
  Column,
  Columns,
  ComboBox,
  ComboBoxItem,
  CopyBox,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Tab,
} from '@meterup/atto';
import { AutoTable, checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import type { SSIDsQueryResult } from '../../../Wireless/SSIDs/SSIDsUtils';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../nav';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useFilteredNetworkClients } from '../../../../routes/pages/network/insights/clients/clients.hooks';
import { ClientsList2 } from '../../../../routes/pages/network/insights/clients/ClientsList2';
import { ClientsListActions } from '../../../../routes/pages/network/insights/clients/ClientsListHeader2';
import { makeDrawerLink, makeLink } from '../../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useNetworkWideCrumbs } from '../../../../utils/routing';
import { NoValue } from '../../../NoValue';
import { PaneContentSkeletons } from '../../../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../../../ReactRouterLink';
import { ssidColumns } from '../../../Wireless/SSIDs/SSIDsList';
import { SSIDsQuery } from '../../../Wireless/SSIDs/SSIDsUtils';
import { type VLAN, vlanHasDHCPRule, vlanHasStaticIP, vlanQuery, vlansQuery } from '../utils';
import VLANActions from '../VLANActions';
import { VLANClientAssignmentSummary, VLANDHCPSummary, VLANDNSSummary } from '../VLANDrawer';
import { VLANDHCPDetails } from './DHCPDetails';
import { VLANDNSDetails } from './DNSDetails';

export enum ValidVLANTab {
  Insights = 'insights',
  DHCP = 'dhcp',
  DNS = 'dns',
}
export const VLAN_TABS_SCHEMA = z.nativeEnum(ValidVLANTab);

export function VLANDetailsDrawer({ vlan }: { vlan: VLAN }) {
  return (
    <>
      <Section relation="stacked">
        <SectionHeader heading="Metadata" />
        <SectionContent gutter="all">
          <SummaryList gutter="none">
            <SummaryListRow>
              <SummaryListKey>ID</SummaryListKey>
              <SummaryListValue>
                <CopyBox aria-label="Copy the VLAN ID">{vlan.vlanID}</CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Description</SummaryListKey>
              <SummaryListValue>
                {vlan.description ? (
                  <CopyBox aria-label="Copy the VLAN description">{vlan.description}</CopyBox>
                ) : (
                  <NoValue />
                )}
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        </SectionContent>
      </Section>
      {vlan.ipV4ClientAssignmentProtocol && (
        <VLANClientAssignmentSummary relation="stacked" vlan={vlan} />
      )}
      {vlanHasDHCPRule(vlan) && (
        <>
          <VLANDHCPSummary relation="stacked" vlan={vlan} view="drawer" />
          <VLANDNSSummary relation="stacked" vlan={vlan} view="drawer" />
        </>
      )}
    </>
  );
}

function VLANSSIDsList({ vlan }: { vlan: VLAN }) {
  const network = useNetwork();
  const companyName = useCurrentCompany();

  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;

  const vlanSSIDs = useMemo(
    () => ssids?.filter((ssid) => ssid.vlan?.UUID === vlan.UUID) ?? [],
    [ssids, vlan.UUID],
  );

  const params = Nav.useRegionParams('drawer', paths.drawers.SSIDEditPage);
  const rowSelected = useCallback(
    (row: SSIDsQueryResult) => params?.uuid === row.UUID,
    [params?.uuid],
  );
  const closeDrawer = useCloseDrawerCallback();

  const renderContent = vlanSSIDs.length ? (
    <AutoTable
      key="ssid-list"
      columns={ssidColumns}
      data={vlanSSIDs}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.SSIDEditPage, {
          networkSlug: network.slug,
          companyName,
          uuid: row.UUID,
        })
      }
      isRowSelected={rowSelected}
      onRowDeselect={closeDrawer}
    />
  ) : (
    <EmptyState icon="ssid" heading="No SSIDs on this VLAN" />
  );

  return (
    <Section relation="stacked">
      <SectionHeader icon="ssid" heading="SSIDs" count={vlanSSIDs.length} />
      <SectionContent>{renderContent}</SectionContent>
    </Section>
  );
}

function VLANDetailsContent({ vlan }: { vlan: VLAN }) {
  const network = useNetwork();
  const networkClientOptions = { filter: { vlanID: vlan.vlanID } };
  const { clients, ...rest } = useFilteredNetworkClients({
    networkClientOptions,
    network,
    useGraphQLOptions: { suspense: false },
  });

  return (
    <Sections>
      <VLANSSIDsList vlan={vlan} />
      <Section relation="stacked">
        <SectionHeader
          icon="client"
          heading="Clients"
          count={clients.length}
          actions={<ClientsListActions {...rest} />}
        />
        <SectionContent>
          <ClientsList2
            networkClientOptions={networkClientOptions}
            emptyStateHeading="No clients found on this VLAN"
          />
        </SectionContent>
      </Section>
    </Sections>
  );
}

function VLANDetailsDetails({ vlan }: { vlan: VLAN }) {
  return (
    <Columns template="object" scroll="independent">
      <Column>
        <VLANDetailsDrawer vlan={vlan} />
      </Column>
      <Column>
        <VLANDetailsContent vlan={vlan} />
      </Column>
    </Columns>
  );
}

function VLANDetailsTabContents({ uuid, activeTab }: { uuid: string; activeTab: ValidVLANTab }) {
  const vlan = checkDefinedOrThrow(
    useGraphQL(vlanQuery, { uuid }).data?.vlan,
    new ResourceNotFoundError('VLAN not found'),
  );

  switch (activeTab) {
    case ValidVLANTab.Insights:
      return <VLANDetailsDetails vlan={vlan} />;
    case ValidVLANTab.DNS:
      return <VLANDNSDetails vlan={vlan} />;
    case ValidVLANTab.DHCP:
      return <VLANDHCPDetails vlan={vlan} />;
  }
}

export default function VLANDetails({ uuid, tab: activeTab }: { uuid: string; tab: ValidVLANTab }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const networkWideCrumb = useNetworkWideCrumbs();
  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;
  const vlan = checkDefinedOrThrow(
    useMemo(() => vlans?.find((v) => v.UUID === uuid), [vlans, uuid]),
    new ResourceNotFoundError('VLAN not found'),
  );
  const navigate = useNavigate();

  const navigateToTab = useCallback(
    (tab: ValidVLANTab) => {
      navigate(
        makeLink(paths.pages.VLANDetailsPage, {
          networkSlug: network.slug,
          companyName,
          uuid: vlan.UUID,
          tab,
        }),
      );
    },
    [navigate, network.slug, companyName, vlan.UUID],
  );

  const navigateToVLAN = useCallback(
    (newUUID: string) =>
      navigate(
        makeLink(paths.pages.VLANDetailsPage, {
          companyName,
          networkSlug: network.slug,
          uuid: newUUID,
          tab: activeTab,
        }),
      ),
    [navigate, companyName, network.slug, activeTab],
  );

  const sortedVLANs = useMemo(
    () => vlans?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [vlans],
  );

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...networkWideCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.VLANListPage, {
                companyName,
                networkSlug: network.slug,
              }),
              label: 'VLANs',
            },
          },
        ]}
        switcher={
          <ComboBox
            size="small"
            maxWidth="100%"
            icon="vlan"
            value={vlan.UUID}
            onValueChange={navigateToVLAN}
          >
            {sortedVLANs.map((v) => (
              <ComboBoxItem key={v.UUID} textValue={v.name}>
                {v.name}
              </ComboBoxItem>
            ))}
          </ComboBox>
        }
        icon="vlan"
        heading={vlan.name}
        badges={
          <Badge
            variant={vlan.isEnabled ? 'positive' : 'negative'}
            ends="pill"
            arrangement="leading-icon"
          >
            {vlan.isEnabled ? 'Enabled' : 'Disabled'}
          </Badge>
        }
        tabs={
          vlanHasStaticIP(vlan) && (
            <>
              <Tab
                icon="reporting"
                selected={activeTab === ValidVLANTab.Insights}
                onClick={() => {
                  navigateToTab(ValidVLANTab.Insights);
                }}
              >
                Insights
              </Tab>
              <Tab
                icon="dhcp"
                selected={activeTab === ValidVLANTab.DHCP}
                onClick={() => {
                  navigateToTab(ValidVLANTab.DHCP);
                }}
              >
                DHCP
              </Tab>
              <Tab
                icon="dns"
                selected={activeTab === ValidVLANTab.DNS}
                onClick={() => {
                  navigateToTab(ValidVLANTab.DNS);
                }}
              >
                DNS
              </Tab>
            </>
          )
        }
        contentActions={<VLANActions vlan={vlan} view="detail" />}
      />
      <PaneContent>
        <Suspense fallback={<PaneContentSkeletons />}>
          <VLANDetailsTabContents activeTab={activeTab} uuid={uuid} />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}
